document.addEventListener('turbo:load', () => {
  let slideIndex = 1;
  let autoPlay = true; // Flag to control autoplay
  let slideInterval; // Variable to hold interval reference
  let startX = 0; // Track touch start X position
  let isDragging = false; // Track if user is dragging

  setMinHeight(); // Set initial height based on longest quote
  showDivs(slideIndex);
  startAutoPlay(); // Start auto-play on page load
  enableSwipe(); // Enable swipe gestures

  function plusDivs(n) {
    autoPlay = false; // Stop autoplay when user interacts
    clearInterval(slideInterval);
    showDivs(slideIndex += n);
  }

  function showDivs(n) {
    const slides = document.getElementsByClassName("book-slide");

    if (slides.length === 0) return; // Prevent errors if no slides exist

    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }

    Array.from(slides).forEach(slide => slide.style.display = "none");
    slides[slideIndex - 1].style.display = "block";
  }

  function startAutoPlay() {
    slideInterval = setInterval(() => {
      if (autoPlay) {
        showDivs(++slideIndex);
      }
    }, 8000); // Change slide every 8 seconds
  }

  function setMinHeight() {
    const slides = document.querySelectorAll(".book-slide");
    let maxHeight = 0;

    slides.forEach(slide => {
      const slideHeight = slide.offsetHeight;
      if (slideHeight > maxHeight) {
        maxHeight = slideHeight;
      }
    });

    // Set the min-height of the carousel div
    const carousel = document.querySelector(".carousel");
    if (carousel) {
      carousel.style.minHeight = `${maxHeight}px`;
    }
  }

  function enableSwipe() {
    const carousel = document.querySelector(".carousel");
    if (!carousel) return;

    carousel.addEventListener("touchstart", handleTouchStart, false);
    carousel.addEventListener("touchmove", handleTouchMove, false);
    carousel.addEventListener("mousedown", handleMouseDown, false);
    carousel.addEventListener("mouseup", handleMouseUp, false);
    carousel.addEventListener("mouseleave", handleMouseUp, false);
  }

  function handleTouchStart(event) {
    startX = event.touches[0].clientX;
  }

  function handleTouchMove(event) {
    if (!startX) return;

    const moveX = event.touches[0].clientX;
    const diffX = startX - moveX;

    if (Math.abs(diffX) > 50) { // Adjust swipe sensitivity
      plusDivs(diffX > 0 ? 1 : -1);
      startX = 0;
    }
  }

  function handleMouseDown(event) {
    isDragging = true;
    startX = event.clientX;
  }

  function handleMouseUp(event) {
    if (!isDragging) return;

    const moveX = event.clientX;
    const diffX = startX - moveX;

    if (Math.abs(diffX) > 50) {
      plusDivs(diffX > 0 ? 1 : -1);
    }

    isDragging = false;
  }

  function enableNavButtons() {
    document.querySelector(".carousel-buttons button:nth-child(1)").addEventListener("click", () => {
      plusDivs(-1);
    });
    document.querySelector(".carousel-buttons button:nth-child(2)").addEventListener("click", () => {
      plusDivs(1);
    });
  }

  function init() {
    if (!document.querySelector(".carousel-buttons")) return;

    setMinHeight();
    showDivs(slideIndex);
    startAutoPlay();
    enableSwipe();
    enableNavButtons();

    window.addEventListener("resize", setMinHeight);
  }

  init();
});
